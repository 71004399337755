export default {
    title: "Inicia la conversación",
    text: "Nos encanta hablar de nuevos y emocionantes desafíos. Hablemos sobre cómo podemos ayudar a que su negocio crezca.",
    form: {
        name: "Nombre",
        email: "Email",
        phone: "Teléfono",
        message: "Mensaje",
        submit: "Enviar",
        successMsg: "¡Gracias por ponerte en contacto! Nos pondremos en contacto con usted en breve. ¡Ten un buen dia!",
        errorMsg: "Se produjo un error. Vuelve a intentarlo más tarde. ¡Gracias!"
    }
}