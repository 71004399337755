import React from "react"
import ReactGA from "react-ga"
import Polyglot from "node-polyglot"
import Suscribe from "../Suscribe"
import { Jumbotron, Container, Row, Col, Button } from "reactstrap"
import content from "../../content"
import Logo from "../Logo"
import "./Hero.css"

//const pathToBG = require.context('../../assets/img/bg/' + process.env.REACT_APP_HERO_BG, true);

const HeroImage = process.env.REACT_APP_HERO_BG
const MailChimpURL = process.env.REACT_APP_MAIL_CHIMP_LIST_URL

const pathToBG = require("../../assets/img/bg/" + HeroImage)

const css = `
    .hero {
        background-image: url(${pathToBG});
    }
`

const Hero = ({ locale }) => {
  const polyglot = new Polyglot({ phrases: { ...content[locale].hero } })

  return (
    <Jumbotron fluid className="hero text-center">
      <Logo />
      <Container fluid>
        <Row>
          <Col>
            <h1 id="content" className="display-3">
              {polyglot.t("title")}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 8, offset: 2 }} sm={{ size: 10, offset: 1 }}>
            <p className="lead">{polyglot.t("intro")}</p>
            {content[locale].hero.cta && (
              <Button
                onClick={() => {
                  console.log("test")
                  ReactGA.ga("send", {
                    hitType: "event",
                    eventCategory: "CTA",
                    eventAction: "click",
                    eventLabel: "hero-cta"
                  })
                  // "CTA", "hero-cta")
                }}
                className="mt-4"
                color="success"
                size="lg"
                href="#contact"
              >
                {polyglot.t("cta")}
              </Button>
            )}
          </Col>
        </Row>

        {MailChimpURL && <Suscribe polyglot={polyglot} url={MailChimpURL} />}
      </Container>
      <style>{css}</style>
    </Jumbotron>
  )
}

export default Hero
