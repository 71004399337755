export default {
  box1: {
    title: "Entender",
    text: "Nos encanta escuchar. Comprender los requisitos comerciales y las necesidades del cliente son las claves para establecer estrategias de productos y soluciones."
  },
  box2: {
    title: "Diseñar",
    text: "Nuestro proceso es iterativo, continuamente realizamos prototipos y probamos nuestras ideas hasta encontrar los conceptos más sólidos."
  },
  box3: {
    title: "Hacer",
    text: "El objetivo es hacer que la experiencia cobre vida, conectarse con los usuarios y la vida de los clientes, ayudando a nuestros clientes a impulsar el cambio."
  }
}