import React from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Fade,
  Spinner
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons"

const formDefaults = {
  "contact-name": "",
  "contact-email": "",
  phone: "",
  message: ""
}

const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY

export default class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      form: { ...formDefaults },
      fadeIn: false,
      fadeInSpinner: false,
      alertColor: "success",
      hasError: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    //                  this.setState({ form: {}, fadeIn: true, alertColor: 'success', hasError: false })
    this.setState({ fadeInSpinner: true })
    if (e.target.checkValidity()) {
      e.preventDefault()
      //console.log("form", this.state.form)
      fetch(`/email/contact.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // sent request
          Accept: "application/json" // expected data sent back
        },
        body: JSON.stringify(this.state.form)
      })
        .then(res => res.json())
        .then(res => {
          console.log("res", res)
          if (res.success) {
            this.setState({
              form: { ...formDefaults },
              fadeIn: true,
              alertColor: "success",
              hasError: false,
              fadeInSpinner: false
            })
          } else {
            this.setState({
              fadeIn: true,
              alertColor: "danger",
              hasError: true,
              fadeInSpinner: false
            })
          }
        })
    } else {
      e.target.reportValidity()
    }
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const newValues = { ...this.state.form, [target.name]: value }
    //    console.log("handleChange", newValues)
    this.setState({ form: newValues })
  }

  render() {
    const {
      name,
      email,
      phone,
      message,
      submit,
      successMsg,
      errorMsg
    } = this.props.content
    return (
      <Form
        action="/email/contact.php"
        method="post"
        onSubmit={this.handleSubmit}
      >
        <FormGroup>
          <Label htmlFor="contact-name">{name}</Label>
          <Input
            value={this.state.form["contact-name"]}
            type="text"
            name="contact-name"
            id="contact-name"
            onChange={this.handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="contact-email">{email} *</Label>
          <Input
            value={this.state.form["contact-email"]}
            type="email"
            name="contact-email"
            id="contact-email"
            onChange={this.handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="phone">{phone}</Label>
          <Input
            value={this.state.form["phone"]}
            type="tel"
            name="phone"
            id="phone"
            onChange={this.handleChange}
          />
        </FormGroup>
        {/*<FormGroup>
          <Label htmlFor="exampleSelect">Select</Label>
          <Input type="select" name="select" id="exampleSelect">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="exampleSelectMulti">Select Multiple</Label>
          <Input type="select" name="selectMulti" id="exampleSelectMulti" multiple>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Input>
        </FormGroup>*/}
        <FormGroup>
          <Label htmlFor="message">{message} *</Label>
          <Input
            value={this.state.form["message"]}
            type="textarea"
            name="message"
            id="message"
            onChange={this.handleChange}
            required
          />
        </FormGroup>
        {/*<FormGroup>
          <Label htmlFor="exampleFile">File</Label>
          <Input type="file" name="file" id="exampleFile" />
          <FormText color="muted">
            This is some placeholder block-level help text for the above input.
            It's a bit lighter and easily wraps to a new line.
          </FormText>
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>Radio Buttons</legend>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" />{' '}
              Option one is this and that—be sure to include why it's great
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" />{' '}
              Option two can be something else and selecting it will deselect option one
            </Label>
          </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              name="isUser"
              onChange={this.handleChange}
              required
            /> {airbnb} *
          </Label>
        </FormGroup>*/}

        <div className="g-recaptcha" data-sitekey={CAPTCHA_KEY}></div>

        <div className="btn-wrapper">
          <Fade in={this.state.fadeInSpinner}>
            <Spinner color="light" size="sm" />
          </Fade>
          <Button type="submit" color="success">
            {submit}
          </Button>
        </div>
        <Alert
          isOpen={this.state.fadeIn}
          color={this.state.alertColor}
          className="contact-msg mt-4"
        >
          <FontAwesomeIcon icon={faThumbsUp} />
          <p>{this.state.hasError ? errorMsg : successMsg}</p>
        </Alert>
      </Form>
    )
  }
}
