import React from "react"
import { Container, Row, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import Logo from "../Logo"
import "./Footer.css"

const hasPowerBy = (process.env.REACT_APP_POWER_BY | 0) > 0
const socialNetworks = JSON.parse(process.env.REACT_APP_SOCIAL_NETWORKS)

const Footer = () => {
  return (
    <footer className="site-footer">
      <Container>
        <Row>
          <Col>
            © {new Date().getUTCFullYear()}
            <span> | </span>
            <Logo />
            {hasPowerBy && (
              <span>
                {" "}
                | Power by{" "}
                <a
                  href="http://threefourteen.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  three.fourteen
                </a>
              </span>
            )}
          </Col>
          {socialNetworks && (
            <Col className="sn-links align-items-center d-flex justify-content-end">
              <span className="sn-links__label">Find us at</span>
              {socialNetworks.map(network => {
                return (
                  <a
                    key={network.name}
                    href={network.url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <span className="sr-only">{network.name}</span>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                )
              })}
            </Col>
          )}
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
