import React from "react"
import Polyglot from "node-polyglot"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap"
import Logo from "../Logo"
import content from "../../content"
import "./Header.css"

//const polyglot = new Polyglot({phrases: {...en.menu}});

export default class Header extends React.Component {
  constructor(props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.state = {
      collapsed: true
    }

    this.polyglot = new Polyglot({ phrases: { ...content[props.locale].menu } })
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }
  render() {
    return (
      <React.Fragment>
        <a className="skippy sr-only sr-only-focusable" href="#content">
          <span className="skippy-text">{this.polyglot.t("skip_content")}</span>
        </a>
        <Navbar color="faded" light>
          <NavbarBrand href="/" className="mr-auto">
            <Logo />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink href="#home">{this.polyglot.t("home")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#our_mission">
                  {this.polyglot.t("our_mission")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#what_we_do">
                  {this.polyglot.t("what_we_do")}
                </NavLink>
              </NavItem>
              {/*<NavItem>
                <NavLink href="#our_solutions">{this.polyglot.t('our_solutions')}</NavLink>
              </NavItem>*/}
              <NavItem>
                <NavLink href="#subscribe">
                  {this.polyglot.t("subscribe")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#contact">{this.polyglot.t("contact")}</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </React.Fragment>
    )
  }
}
