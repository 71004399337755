import React from "react"
import ReactGA from "react-ga"
import { Container, Row, Col } from "reactstrap"
import {
  Header,
  Hero,
  Mission,
  Boxes,
  Clients,
  Contact,
  Footer,
  Languages
} from "../components"

// Mail chimp list URL
const hasHeader = (process.env.REACT_APP_HAS_HEADER | 0) > 0
const lang = JSON.parse(process.env.REACT_APP_LANGUAGES)

const MainPage = ({ locale }) => {
  if (process.env.NODE_ENV === "production") {
    const pageview = locale === "es" ? "/homepage_es" : "/homepage"
    ReactGA.pageview(pageview)
  }
  return (
    <>
      {hasHeader && <Header locale={locale} />}
      {lang && <Languages locale={locale} lang={lang} />}
      <Hero locale={locale} />
      <Mission locale={locale} />
      <Boxes locale={locale} />
      <Container className="boxes pb-5 pt-4">
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
      </Container>
      <Clients locale={locale} />
      <Contact locale={locale} />
      <Footer />
    </>
  )
}

export default MainPage
