export default {
  title: "El puente a sus Metas",
  introold: "Somos el puente para alcanzar tus metas",
  intro: "Porque nos encanta resolver problemas del mundo real",
  cta: "Obten un Presupuesto",
  form: {
    name_label: "name",
    email_label: "email",
    submit_label: "submit",
    sending: "sending",
    success_msg: "subscribed!"
  }
}
