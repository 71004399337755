import React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { Alert, Spinner, Button, Form, FormGroup, Label, Input } from "reactstrap"
import "./Suscribe.css"

// a basic form
const CustomForm = ({ status, message, onValidated, polyglot }) => {
  let email, name
  const submit = () =>
    email &&
    name &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value
    })

  return (
    <Form inline>
      <FormGroup>
        <Label htmlFor="name" hidden>
          Email
        </Label>
        <Input
          bsSize="sm"
          ref={node => (name = node)}
          type="name"
          required
          id="name"
          placeholder={polyglot.t("form.name_label")}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="email" hidden>
          Email
        </Label>
        <Input
          bsSize="sm"
          ref={node => (email = node)}
          type="email"
          required
          id="email"
          placeholder={polyglot.t("form.email_label")}
        />
      </FormGroup>
      <Button color="secondary" size="sm" onClick={submit}>
        {polyglot.t("form.submit_label")}
      </Button>

      {status === "sending" && (
        <div className="text-primary">
          {polyglot.t("sending")}…
          <Spinner type="grow" color="secondary" />
        </div>
      )}
      {status === "error" && (
        <Alert color="danger">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </Alert>
      )}
      {status === "success" && <Alert color="success">{polyglot.t("success_msg")}</Alert>}
    </Form>
  )
}

// use the render prop and your custom form
const Suscribe = props => {
  return (
    <MailchimpSubscribe
      url={props.url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          polyglot={props.polyglot}
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )}
    />
  )
}

export default Suscribe
