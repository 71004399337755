import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import ReactGA from "react-ga"
//import getUserLocale from "get-user-locale"
import { MainPage } from "./pages"

//let locale = getUserLocale()
//locale = locale.substring(0, 2) === "es" ? "es" : "en"
//console.log("localle",locale, getUserLocale())

// Google Analytics
const GA_CODE = process.env.REACT_APP_GA_CODE
function initializeReactGA() {
  ReactGA.initialize(GA_CODE)
}

export default function App() {
  if (process.env.NODE_ENV === "production") {
    initializeReactGA()
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <MainPage locale="en" />
        </Route>
        <Route path="/es">
          <MainPage locale="es" />
        </Route>
      </Switch>
    </Router>
  )
}
