import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Polyglot from 'node-polyglot';
import content from '../../content';
import './Mission.css';

function Mission({locale}) {
  const polyglot = new Polyglot({phrases: {...content[locale].mission}});
  return (
    <Container>
      <Row>
        <Col>
          <h2>{polyglot.t('title')} <span className="brand-color">three</span>.<span className="brand-color">fourteen</span></h2>
          <p>{polyglot.t('text')}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Mission;
