export default {
  title: "Quiénes confían en nosotros",
  subtitle:
    "Nuestros clientes nos hacen quienes somos y nos sentimos muy afortunados de haber trabajado con ellos.",
  tooltipPrefix: "Ayudamos con ",
  data: [
    {
      name: "Kantar Ibope Media",
      url: "https://www.kantaribopemedia.com.ar",
      stack: "Design & Web Development",
      logo:
        "https://res.cloudinary.com/jobsio/image/upload/v1588011279/threefourteen/kantar.png"
    },
    {
      name: "Banking Bridge",
      url: "https://bankingbridge.com/",
      stack: "UX Design & UI development",
      logo:
        "https://res.cloudinary.com/jobsio/image/upload/v1588011279/threefourteen/banking-bridge.png"
    },
    {
      name: "Lymphedivas: Fitter's Aid App",
      url: "https://lymphedivas.com/fitters-aid-app/",
      stack: "UI development",
      logo:
        "https://res.cloudinary.com/jobsio/image/upload/v1588011279/threefourteen/lymphedivas.png"
    },
    {
      name: "Tarjeta La Anonima: Home banking",
      url: "https://secure.tarjetalaanonima.com.ar/GestionOnline/login",
      stack: "UX design & prototyping",
      logo:
        "https://res.cloudinary.com/jobsio/image/upload/v1588011279/threefourteen/la-anonima.png"
    },
    {
      name: "Second Opinion Expert",
      url: "https://apps.apple.com/ar/app/secondopinionexpert/id964310036?l=en",
      stack: "Mobile development",
      logo:
        "https://res.cloudinary.com/jobsio/image/upload/v1588011279/threefourteen/second-opinion-expert.png"
    }
  ]
}
