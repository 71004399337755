export default {
    title: "Start the conversation",
    text: "We love discussing new and exciting challenges. Let's talk about how we can help your business grow.",
    form: {
        name: "Name",
        email: "Email",
        phone: "Phone",
        message: "Message",
        submit: "Submit",
        successMsg: "Thank you for getting in touch! We will be getting back to you shortly. Have a great day ahead!",
        errorMsg: "There was an error, please try again later. Thanks!"
    }
}