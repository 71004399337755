export default {
  title: "The bridge to your goals",
  introold: "We are the bridge to achieve your goals",
  intro: "Because we love to solve real world problems",
  cta: "Get a Quote",
  form: {
    name_label: "name",
    email_label: "email",
    submit_label: "submit",
    sending: "sending",
    success_msg: "subscribed!"
  }
}
