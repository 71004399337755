import React from "react"
import Polyglot from "node-polyglot"
import { Container, Row, Col, Tooltip } from "reactstrap"
import content from "../../content"
import "./Clients.css"

export default class Clients extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: [false, false, false, false, false]
    }
  }

  toggle = index => {
    let tooltipOpen = [...this.state.tooltipOpen]
    tooltipOpen[index] = !tooltipOpen[index]
    this.setState({ tooltipOpen: tooltipOpen })
  }

  render() {
    const polyglot = new Polyglot({
      phrases: { ...content[this.props.locale].clients }
    })
    return (
      <Container className="clients pb-5 ">
        <Row>
          <Col className="pb-3">
            <h2>{polyglot.t("title")}</h2>
            <p>{polyglot.t("subtitle")}</p>
          </Col>
        </Row>
        <Row>
          <Col
            sm={{ size: 12 }}
            xs={{ size: 10, offset: 1 }}
            lg={{ size: 8, offset: 2 }}
            className="clients__grid d-flex justify-content-center flex-wrap"
          >
            {content[this.props.locale].clients.data.map((client, index) => (
              <div className="p-2" key={client.name}>
                <h3 className="sr-only">{client.name}</h3>
                <p className="sr-only">
                  {polyglot.t("tooltipPrefix")}
                  {client.stack}
                </p>
                <Tooltip
                  placement="bottom"
                  toggle={() => {
                    this.toggle(index)
                  }}
                  target={`logo-${index}`}
                  isOpen={this.state.tooltipOpen[index]}
                >
                  {polyglot.t("tooltipPrefix")}
                  {client.stack}
                </Tooltip>
                <a
                  href={client.url}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img
                    id={`logo-${index}`}
                    className="clients__logo text-center"
                    src={client.logo}
                    alt={`${client.name} logo`}
                  />
                </a>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    )
  }
}
