import React from "react"
import Polyglot from "node-polyglot"
import { Container, Row, Col } from "reactstrap"
import ContactForm from "./ContactForm"
import content from "../../content"
import "./Contact.css"

const Contact = ({ locale }) => {
  const polyglot = new Polyglot({ phrases: { ...content[locale].contact } })
  return (
    <Container id="contact" fluid className="contact py-5">
      <Row>
        <Col md={{ size: 6, offset: 3 }} className="text-center">
          <h3>{polyglot.t("title")}</h3>
          <p>{polyglot.t("text")}</p>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 6, offset: 3 }} sm={{ size: 8, offset: 2 }}>
          <ContactForm content={content[locale].contact.form} />
        </Col>
      </Row>
    </Container>
  )
}

export default Contact
