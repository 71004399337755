export default {
  home: "Home",
  our_mission: "Our mission",
  what_we_do: "What we do",
  our_solutions: "Our solutions",
  subscribe: "Subscribe",
  contact: "Contacto",
  skip_content: "Skip to main content",
  choose_language: "Elige el idioma"
}
