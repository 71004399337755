import React from "react"
import Polyglot from "node-polyglot"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import content from "../../content"
import "./Languages.css"

const Languages = ({ locale, lang }) => {
  const polyglot = new Polyglot({ phrases: { ...content[locale].menu } })
  return (
    <div className="lang">
      <div className="align-items-center d-flex justify-content-end">
        <FontAwesomeIcon icon={faGlobe} className="lang__icon" />
        <span className="sr-only">{polyglot.t("choose_language")}</span>
        {lang.map(item => {
          return (
            <NavLink
              exact
              className="lang__link"
              key={item.id}
              to={item.isMain ? "/" : `/${item.id}`}
            >
              {item.id}
            </NavLink>
          )
        })}
      </div>
    </div>
  )
}

export default Languages
