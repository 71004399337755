export default {
  box1: {
    title: "Understand",
    text: "We love to hear. Understanding business requirements and customer needs are the keys to establishing product and solution strategies."
  },
  box2: {
    title: "Design",
    text: "Our process is iterative, we continuously prototype and test our ideas until we come up with the strongest concepts."
  },
  box3: {
    title: "Do",
    text: "The objective is to make the experience come to life, connect with users and customers lives, helping our clients drive the change."
  }
}