import React from 'react';
import PropTypes from 'prop-types';
import './Logo.css';
import logo from '../../assets/img/logo.svg'

const siteName = process.env.REACT_APP_SITE_NAME
const hasImgLogo = (process.env.REACT_APP_SITE_LOGO | 0) > 0

const siteInfo = {
  name: siteName
}

if(hasImgLogo){
  siteInfo.logo = logo
}

function Logo({isText}) {
  const className = siteInfo.logo ? 'sr-only' : 'logo'
  return (
    <>
      {!isText && siteInfo.logo && <img src={logo} alt={`${siteInfo.name} logo`} className="logo__img" />}
      <h2 className={className}>
        {siteInfo.name}
      </h2>
    </>
  );
}

Logo.propTypes = {
  isText: PropTypes.bool
}

Logo.defaultProps = {
  isText: false
}

export default Logo;
