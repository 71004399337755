import React from 'react';
import Polyglot from 'node-polyglot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faRocket, faPencilRuler } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col } from 'reactstrap';
import content from '../../content';
import './Boxes.css';

export default class Boxes extends React.PureComponent {
  render() {
    const polyglot = new Polyglot({phrases: {...content[this.props.locale].boxes}});
    return (
      <Container className="boxes pb-5 pt-4">
        <Row>
          <Col xs="12" sm="4">
            <FontAwesomeIcon icon={faComments} />
            <h3>{polyglot.t('box1.title')}</h3>
            <p>{polyglot.t('box1.text')}</p>
          </Col>
          <Col xs="12" sm="4">
            <FontAwesomeIcon icon={faPencilRuler} />
            <h3>{polyglot.t('box2.title')}</h3>
            <p>{polyglot.t('box2.text')}</p>
          </Col>
          <Col xs="12" sm="4">
            <FontAwesomeIcon icon={faRocket} />
            <h3>{polyglot.t('box3.title')}</h3>
            <p>{polyglot.t('box3.text')}</p>
          </Col>
        </Row>
      </Container>
    );
  }
}
